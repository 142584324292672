import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import locationIcon from '../icons/location_pin.svg'
import clockIcon from '../icons/clock_icon.svg'

const upcomingEvents = require("../../../meetupData.json").upcomingEvents;

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const Section3 = ({}) => {
  return (
    <section className="container pt-0 py-10 md:pt-12 md:py-26 lg:pt-20 lg:py-24 xl2:pt-24 xl2:py-40" id="events-list">
      <div className="text-sm text-gradient uppercase font-display font-bold">Kalender</div>
      <h2 className="text-2xl lg:text-4xl leading-none font-display font-bold">Events & Workshops</h2>
      <hr className="border-meteor-500 border-2 w-1/5 my-6" />

      {upcomingEvents.length === 0 && (<div className="text-xl text-center py-16">
        Zur Zeit finden COVID-19 bedingt keine Events statt. Schaut bald wieder vorbei.
      </div>)}

      <div className="mt-12">
        {upcomingEvents.map(event => (
          <React.Fragment key={event.id}>
            <div className={`lg:flex ${event.status === 'past' && 'greyscale'}`}>
              <div className="flex lg:w-1/2 mb-4 lg:mb-0">
                <div className="lg:mr-8 flex-grow-0">
                  <div className={`text-white rounded-full bg-gray-200 bg-gradient-l-gray-300 leading-none flex flex-col items-center justify-center w-24 h-24 ${event.status !== 'past' && 'bg-meteor-500 bg-gradient-l-mulberry-500'}`}>
                    <div className="font-display font-bold text-5xl mb-0">{(new Date(event.time)).getDate()}.</div>
                    <div className="font-light text-xs">{months[(new Date(event.time)).getMonth()]} {(new Date(event.time)).getFullYear()}</div>
                  </div>
                </div>

                <div className="ml-4 lg:mx-8 flex-grow leading-tight flex flex-col justify-center flex-no-wrap">
                  <a href={event.link} target="_blank" className="hover:underline">
                    <div className="font-bold text-xl">{event.name.split(": ")[0]}{event.name.split(": ").length > 1 ? ":" : ""}</div>
                    <div className="text-lg">{event.name.split(": ")[1]}</div>
                  </a>
                </div>
              </div>

              <a href={`https://www.google.com/maps/search/?api=1&query=${event.venue?.lat},${event.venue?.lon}`} target="_blank" className="block lg:border-l mb-4 lg:mb-0 border-meteor-500 lg:mx-4 lg:px-4 lg:w-3/12 font-light leading-relaxed text-sm flex items-center justify-center">
                <img src={locationIcon} className="w-8 lg:w-6 mr-6" />
                <div className="w-40 lg:w-auto">
                  {event.venue?.name}<br />
                  {event.venue?.address_1}<br />
                  {event.venue?.city}
                </div>
              </a>

              <div className="lg:border-l border-meteor-500 lg:ml-4 lg:pl-4 lg:w-3/12 font-light leading-relaxed text-sm flex items-center justify-center">
                <img src={clockIcon} className="w-8 lg:w-6 mr-6" />
                <div className="w-40 lg:w-auto">
                  {(new Date(event.time)).getDate()}. {months[(new Date(event.time)).getMonth()]} {(new Date(event.time)).getFullYear()}<br />
                  Start @ {event.local_time}<br />
                </div>
              </div>
            </div>
            <hr className="my-8" />
          </React.Fragment>
        ))}
      </div>
    </section>
  )
}

Section3.propTypes = {
}

Section3.defaultProps = {
}

export default Section3
